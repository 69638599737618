import React, {Component} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../../config';
import Spinner from "../elements/Spinner";


export class PhotosGallery extends Component {
    state = {
        isLoaded: false,
    }

    componentDidMount() {

        this.setState({
            isLoaded: true
        })
    }

    render() {
        var settings = {
            infinite: true,
            speed: 900,
            autoplaySpeed: 3000,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            swipeToSlide: true,
            dots: true,
            centerMode: true,
            arrows: false
        };

        const {isLoaded} = this.state;
        const {photos} = this.props;
        if(isLoaded) {
            return(
                <Slider {...settings} className={"photo-gallery-slider"}>
                    {
                        photos.map((photo, index) => {
                            return(
                                <img key={index} className={"photo-gallery-slider-object"} alt={photo.photo.filename} src={photo.photo.sizes.large} />
                        )})
                    }
                </Slider>
            )
        }
        return (
            <Spinner />
        );
    }
}

export default PhotosGallery;