import React, {Component} from "react";
import Lottie from "lottie-react";
import spinner from '../../images/spinner';


export class Spinner extends Component {
    

    render() {
        const style = {
            height: 50,
          };

        return(
            <div id={"spinner-container"}>
                {/* <Lottie
                    options={defaultOptions}
                    height={50}
                    width={50}
                    style={{margin: "2em"}}
                    config={""}/>  */}

                <Lottie animationData={spinner} 
                        loop={true}  
                        style={style}/>
            </div>
        );
    }
}

export default Spinner;