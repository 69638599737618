import React, {Component} from 'react';
// import {GoogleApiWrapper, InfoWindow, Map, Marker} from 'google-maps-react';

const mapStyles = {
    width: '100%',
    height: '100%',
    zIndex: '1',
    border: '0'
};

export class MapContainer extends Component {
    state = {
        showingInfoWindow: false,  //Hides or the shows the infoWindow
        activeMarker: {},          //Shows the active marker upon click
        selectedPlace: {}          //Shows the infoWindow to the selected place upon a marker
    };

    onMarkerClick = (props, marker, e) =>
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });

    onClose = props => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            });
        }
    };

    render() {
        return (
            <div id={"map"}>
                {/* <Map
                    google={this.props.google}
                    zoom={15}
                    style={mapStyles}
                    containerStyle={{position: 'relative'}}
                    initialCenter={{
                        lat: 50.680498,
                        lng: 17.950237
                    }}
                >
                    <Marker
                        onClick={this.onMarkerClick}
                        name={'Publiczna Szkoła Podstawowa nr 5\nz Oddziałami Integracyjnymi\nim. Karola Musioła w Opolu '}
                    />
                    <InfoWindow
                        marker={this.state.activeMarker}
                        visible={this.state.showingInfoWindow}
                        onClose={this.onClose}
                        google={this.props.google} >
                        <div id={"info-window"}>
                            {this.state.selectedPlace.name}
                        </div>
                    </InfoWindow>
                </Map> */}

                <iframe 
                    title="google-maps"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2528.1245440990438!2d17.948419915730376!3d50.68051317950781!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471053bc5eead91d%3A0x851df7680a0bf2a9!2sPubliczna%20Szko%C5%82a%20Podstawowa%20nr%205%20z%20Oddzia%C5%82ami%20Integracyjnymi%20im%20Karola%20Musio%C5%82a!5e0!3m2!1spl!2spl!4v1675268448877!5m2!1spl!2spl" 
                    loading="lazy" 
                    referrerPolicy="no-referrer-when-downgrade"
                    style={mapStyles}/>
            </div>

        );
    }
}

export default MapContainer;